export function animateSliderItems(
  sliderItems,
  animationOptions,
  beforeAnimationState
) {
  let sliderInterval = null;
  const loop = animationOptions.get("loop") ?? false;
  const duration = animationOptions.get("duration");
  const restartAfterDelay = duration * sliderItems.length;

  return () => {
    _sliderAnimationStart(sliderItems, animationOptions, beforeAnimationState);

    if (!loop) return;

    // Loop the animation after the first animation finishes
    sliderInterval = setInterval(() => {
      _sliderAnimationStart(
        sliderItems,
        animationOptions,
        beforeAnimationState
      );
    }, restartAfterDelay);

    // If the tab is inactive, clear the setInterval for slider animation
    // and then restart it again when the tab is active
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        // Inactive tab
        clearInterval(sliderInterval);
        sliderItems.forEach((item) => {
          item.style.animation = "none";
        });
      } else {
        // Active tab
        _sliderAnimationStart(
          sliderItems,
          animationOptions,
          beforeAnimationState
        );
        sliderInterval = setInterval(() => {
          _sliderAnimationStart(
            sliderItems,
            animationOptions,
            beforeAnimationState
          );
        }, restartAfterDelay);
      }
    });
  };
}

function _sliderAnimationStart(
  sliderItems,
  animationOptions,
  beforeAnimationState
) {
  sliderItems.forEach((item, index) => {
    beforeAnimationState?.forEach((value, property) => {
      item.style[property] = value;
    });
    item.style.animation = "none";
    item.offsetWidth;
    const animationString = _createAnimationString(index, animationOptions);
    item.style.animation = animationString;
  });
}

function _createAnimationString(index, animationOptions) {
  const name = animationOptions.get("name");
  const duration = animationOptions.get("duration");
  const delay = animationOptions.get("delay") ?? 0;
  const modifiedDelay = delay + index * duration;
  const easing = animationOptions.get("easing");
  const fill = animationOptions.get("fill");

  const animationString = `${name} ${duration}ms ${modifiedDelay}ms ${
    easing ?? ""
  } ${fill ?? ""}`;

  return animationString;
}

// export function wrapWordsWithElement(
//   element,
//   wrapElement = "span",
//   options = {}
// ) {
//   const textNodes = _getTextNodes(element);

//   textNodes.forEach((node) => {
//     const words = node.textContent.split(/\b/);
//     const fragment = document.createDocumentFragment();
//     let hasWords = false;

//     words.forEach((word) => {
//       if (word.trim() !== "") {
//         const wrap = document.createElement(wrapElement);
//         wrap.textContent = word;
//         _applyStyles(wrap, options.styles);
//         if (options.className) {
//           wrap.classList.add(options.className);
//         }
//         fragment.appendChild(wrap);
//         hasWords = true;
//       } else {
//         fragment.appendChild(document.createTextNode(word));
//       }
//     });

//     if (hasWords) {
//       node.replaceWith(fragment);
//     }
//   });
// }

// Usage example
// // wrapWordsWithElement(feedback, "span", {
// //   className: "",
// //   styles: {},
// // });

export function addRemoveClassWithinTime(
  elem,
  classAdd,
  classRemove,
  { callbackFunc = null, resetAfterTime = 0 }
) {
  if (!(elem instanceof HTMLElement)) {
    throw new Error("Invalid element provided.");
  }

  if (typeof classAdd !== "string" || typeof classRemove !== "string") {
    throw new Error("Class names must be strings.");
  }

  let elemTimeoutId = null;

  return () => {
    elem.classList.add(classAdd);
    elem.classList.remove(classRemove);

    if (elemTimeoutId) clearTimeout(elemTimeoutId);

    if (callbackFunc) callbackFunc();

    if (!resetAfterTime) return Promise.resolve();

    return new Promise((resolve) => {
      elemTimeoutId = setTimeout(() => {
        requestAnimationFrame(() => {
          elem.classList.add(classRemove);
          elem.classList.remove(classAdd);
          resolve();
        });
      }, resetAfterTime);
    });
  };
}

// Function to copy text in the clipboard
export async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("Error copying text to clipboard:", error);
  }
}

function _applyStyles(element, styles) {
  Object.assign(element.style, styles);
}

function _getTextNodes(element) {
  const textNodes = [];

  function traverse(node) {
    const childNodes = node.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      if (childNode.nodeType === Node.TEXT_NODE) {
        textNodes.push(childNode);
      } else if (childNode.nodeType === Node.ELEMENT_NODE) {
        traverse(childNode);
      }
    }
  }

  traverse(element);
  return textNodes;
}

// ========================================= Library Imports
import "dotenv/config";

// ========================================= Local Imports
import { animateSliderItems } from "./slider";
import { addRemoveClassWithinTime, copyToClipboard } from "./utilities";

// ========================================= Selectors & Variables
// ========= Creating variables to consider reduced motion & no-preference motion
const prefersMotion = window.matchMedia(
  "(prefers-reduced-motion: no-preference)"
).matches;
const hoverMedia = window.matchMedia("(hover: hover)").matches;

// ========================================= Function Definitions & Calls
function initialFunctionalitySetup() {
  const MY_EMAIL_ID = process.env.CONTACT_EMAIL_ID;

  const copyEmailBtn = document.querySelector(".btn[data-copy-email]");
  if (copyEmailBtn) {
    const emailCopiedFunc = addRemoveClassWithinTime(
      copyEmailBtn,
      "btn--copied",
      "btn--neutral",
      {
        callbackFunc: () => copyToClipboard(MY_EMAIL_ID),
        resetAfterTime: 2000,
      }
    );

    copyEmailBtn.addEventListener("mousedown", (e) => e.preventDefault());
    copyEmailBtn.addEventListener("click", emailCopiedFunc);
  }

  const mailToLink = document.querySelector("a[data-mailto-link]");
  if (mailToLink) mailToLink.href = `mailto:${MY_EMAIL_ID}`;

  const disabledLinks = document.querySelectorAll("a[data-disabled]");
  if (disabledLinks?.length) {
    disabledLinks.forEach((disabledLink) =>
      disabledLink.addEventListener("click", (e) => e.preventDefault())
    );
  }
}

initialFunctionalitySetup();

// ========================================= Dynamic Imports & Function Calls
// Function to load a script dynamically
function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.defer = true;
    script.onerror = () => {
      reject(new Error(`Error loading script: ${src}`));
    };
    script.onload = () => {
      resolve(true);
    };
    document.head.appendChild(script);
  });
}

// Function to initialize smooth scrollbar functionality
async function initializeSmoothScrollbar() {
  try {
    const mySmoothScrollbarModule = await import("./my-smooth-scrollbar");
    const mySmoothScrollbar = mySmoothScrollbarModule.default;
    // Customize the parameters based on your requirements
    const contentSelector = "#smooth-scroll-content";
    const viewportSelector = "#smooth-scroll-container";
    const smoothness = 1.2;
    const smoothFocusBehavior = true;
    const smoothFocusDuration = 0.8;

    // Initialize smooth scroll effect
    const { scroller: mySmoothScroller, myHandleLoadFocus } = mySmoothScrollbar(
      contentSelector,
      viewportSelector,
      smoothness,
      {
        smoothFocusBehavior,
        smoothFocusDuration,
      }
    );
    window.myHandleLoadFocus = myHandleLoadFocus;
  } catch (error) {
    console.error(
      "Error occurred during smooth scrollbar initialization:",
      error
    );
  }
}

// Function to initialize cursor trailer functionality
async function initializeCursorTrailer() {
  try {
    // ========= Cursor Animation Variables
    const cursorTrailerClasses = [];
    const cursorTrailerCircleClasses = [];
    const CURSOR_CATCHUP_DURATION = 0.5;
    const CURSOR_EASING = "power3";
    const cursorDataAttrString = "data-cursor-trailer";

    const cursorTrailerModule = await import("./cursor-trailer");
    const { moveCursorTrailer, _refreshCursorTrailerAttr } =
      cursorTrailerModule;
    const { cursorTrailer } = moveCursorTrailer(
      CURSOR_CATCHUP_DURATION,
      CURSOR_EASING,
      cursorDataAttrString,
      cursorTrailerClasses,
      cursorTrailerCircleClasses
    );
    window._refreshCursorTrailerAttr = () =>
      _refreshCursorTrailerAttr(cursorTrailer, cursorDataAttrString);
  } catch (error) {
    console.error(
      "Error occurred during cursor trailer initialization:",
      error
    );
  }
}

// Function to initialize animation functionality
async function initializeAnimation() {
  try {
    const animations = await import("./animations");
    const { initWatermarkAnimation, initFeedbackAnimation } = animations;

    // Watermark Animation
    if (hoverMedia) {
      const watermarkElems = ".watermark";
      const watermarkParentSelector = "section";
      initWatermarkAnimation(watermarkElems, watermarkParentSelector);
    }

    // Feedback Animation
    const feedbackSelector = ".feedback-sec__feedback";
    initFeedbackAnimation(feedbackSelector, { opacity: 0 }, { opacity: 1 }, 5, {
      ease: "none",
      loop: true,
    });
  } catch (error) {
    console.error("Error occurred during animation initialization:", error);
  }
}

// Function to initialize tooltip animation functionality
async function initializeTooltipAnimation() {
  try {
    const animations = await import("./my-tooltip");
    const { initTooltipAnimation } = animations;

    // Tooltip Animation
    const tooltipDataAttr = "data-tooltip-text";
    const tooltipClasses = "p2-desc my-tooltip";
    const tooltipUniqueIdentityClass = "_myToolTip";
    const tooltipAppendTo = document.querySelector("#smooth-scroll-content");

    initTooltipAnimation(
      tooltipDataAttr,
      tooltipClasses,
      tooltipUniqueIdentityClass,
      tooltipAppendTo
    );
  } catch (error) {
    console.error("Error occurred during animation initialization:", error);
  }
}

// Function to initialize tooltip animation functionality
async function initializePageTransitions() {
  try {
    const pageTransitions = await import("./page-transitions");
    const { initPageTransitions } = pageTransitions;

    const callbackFunctions = {
      all: {
        leave(data) {
          ScrollTrigger.refresh();
        },
        afterEnter(data) {
          initialFunctionalitySetup();
        },
      },
      home: {
        afterEnter(data) {
          if (window._refreshCursorTrailerAttr)
            window._refreshCursorTrailerAttr();
          initializeAnimation();
        },
      },
      caseStudy: {
        afterEnter(data) {
          if (window._refreshCursorTrailerAttr)
            window._refreshCursorTrailerAttr();
          initializeTooltipAnimation();
        },
      },
    };

    initPageTransitions(callbackFunctions);
  } catch (error) {
    console.error(
      "Error occurred during page transitions initialization:",
      error
    );
  }
}

// Function to handle dynamic imports and function calls
async function initializeDynamicFunctionality(options) {
  try {
    const { libraryScripts, asyncFunctions } = options;

    if (libraryScripts && libraryScripts.length > 0) {
      const scriptPromises = libraryScripts.map(loadScript);
      const scriptsLoaded = await Promise.all(scriptPromises);

      const allScriptsLoaded = scriptsLoaded.every(Boolean);
      if (!allScriptsLoaded) {
        throw new Error("Failed to load one or more library scripts.");
      }
    }

    if (asyncFunctions && asyncFunctions.length > 0) {
      const functionPromises = asyncFunctions.map((fn) => fn());
      await Promise.all(functionPromises);
    }
  } catch (error) {
    console.error("Error occurred during initialization:", error);
  }
}

// Loader Animation
window.addEventListener("DOMContentLoaded", () => {
  const myCSSLoader = document.querySelector("[data-page-loader]");
  myCSSLoader.classList.add("loaded");
  myCSSLoader.addEventListener("animationend", () => myCSSLoader.remove(), {
    once: true,
  });
});

if (prefersMotion) {
  if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
  }

  if (hoverMedia) {
    initializeDynamicFunctionality({
      libraryScripts: [
        "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/gsap.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/ScrollTrigger.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.1/ScrollToPlugin.min.js",
        "https://cdn.jsdelivr.net/npm/@barba/core",
      ],
      asyncFunctions: [
        initializeSmoothScrollbar,
        initializeCursorTrailer,
        initializePageTransitions,
      ],
    });
  } else {
    initializeDynamicFunctionality({
      libraryScripts: [
        "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/gsap.min.js",
        "https://cdn.jsdelivr.net/npm/@barba/core",
      ],
      asyncFunctions: [initializePageTransitions],
    });
  }
} else {
  // ========= For Feedback Animation Variables
  const feedbacks = document.querySelectorAll(".feedback-sec__feedback");
  if (feedbacks.length) {
    const FEEDBACK_ANIMATION_DURATION = 5000;
    const BEFORE_ANIMATION_STATE = new Map([["opacity", 0]]);
    const ANIMATION_OPTIONS = new Map([
      ["name", "appearIn"],
      ["duration", FEEDBACK_ANIMATION_DURATION],
      ["delay", 0],
      ["easing", "ease-in-out"],
      ["fill", "forwards"],
      ["loop", true],
    ]);

    // ========= For Feedback Animation Calls
    const animateFeedbacks = animateSliderItems(
      feedbacks,
      ANIMATION_OPTIONS,
      BEFORE_ANIMATION_STATE
    );
    animateFeedbacks();
  }

  // Tooltip animation call
  if (document.querySelectorAll("[data-tooltip-text]").length) {
    initializeDynamicFunctionality({
      libraryScripts: [
        "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/gsap.min.js",
      ],
      asyncFunctions: [initializeTooltipAnimation],
    });
  }
}
